/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { 
  RiArrowLeftSLine, 
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiStarFill,
  RiStarLine,
  RiInformationLine,
  RiPriceTag3Line,
  RiUserSmileLine,
  RiComputerLine,
  RiCustomerService2Line
} from "react-icons/ri"

const PerformanceManagementPage = () => {
  // Performance Management Software comparison data
  const pmSoftwareComparison = [
    {
      name: "15Five",
      website: "https://www.15five.com",
      priceRange: "$$",
      pricingModel: "Per user/month (annual billing)",
      userRating: 4.6,
      userReviews: 850,
      usability: 4.7,
      mobileFriendly: true,
      continuousFeedback: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "OKR and goal tracking",
        "Weekly check-ins",
        "360° feedback",
        "1-on-1 meeting agendas",
        "Recognition system"
      ],
      limitations: [
        "Limited enterprise reporting",
        "Basic integration options",
        "Limited customization options",
        "Higher price for full feature set"
      ],
      bestFor: "Growing companies seeking an employee-centric performance approach"
    },
    {
      name: "Lattice",
      website: "https://lattice.com",
      priceRange: "$$",
      pricingModel: "Per user/month (annual billing)",
      userRating: 4.7,
      userReviews: 920,
      usability: 4.8,
      mobileFriendly: true,
      continuousFeedback: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Goals and OKR tracking",
        "Performance reviews",
        "1-on-1 meetings",
        "Feedback and recognition",
        "Employee engagement surveys"
      ],
      limitations: [
        "Price increases with added modules",
        "Some advanced features only in higher tiers",
        "Limited customization for smaller plans",
        "Some reported customer support issues"
      ],
      bestFor: "Mid-sized companies wanting to combine performance with engagement measurement"
    },
    {
      name: "Culture Amp",
      website: "https://www.cultureamp.com",
      priceRange: "$$$",
      pricingModel: "Per employee/year",
      userRating: 4.5,
      userReviews: 780,
      usability: 4.4,
      mobileFriendly: true,
      continuousFeedback: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Performance reviews",
        "Goal management",
        "Employee engagement surveys",
        "360° feedback",
        "Action planning tools"
      ],
      limitations: [
        "Higher pricing tier",
        "Complex implementation for all features",
        "Advanced analytics can be overwhelming",
        "Requires strong admin support"
      ],
      bestFor: "Data-driven organizations that want to combine engagement and performance"
    },
    {
      name: "Workday Performance Management",
      website: "https://www.workday.com/performance",
      priceRange: "$$$$",
      pricingModel: "Custom enterprise pricing",
      userRating: 4.1,
      userReviews: 620,
      usability: 3.8,
      mobileFriendly: true,
      continuousFeedback: true,
      companySize: "Enterprise",
      keyFeatures: [
        "Calibration tools",
        "Continuous feedback",
        "Goal alignment",
        "Talent review capabilities",
        "Full HCM integration"
      ],
      limitations: [
        "Significant implementation effort",
        "Higher complexity for users",
        "Considerable cost investment",
        "Requires dedicated admin resources"
      ],
      bestFor: "Large enterprises using Workday as their core HR platform"
    },
    {
      name: "PerformYard",
      website: "https://performyard.com",
      priceRange: "$$",
      pricingModel: "Per employee/month",
      userRating: 4.5,
      userReviews: 580,
      usability: 4.6,
      mobileFriendly: true,
      continuousFeedback: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Flexible review cycles",
        "Goal management",
        "Continuous feedback tools",
        "Real-time recognition",
        "Customizable workflows"
      ],
      limitations: [
        "Limited advanced analytics",
        "Fewer built-in templates than competitors",
        "Basic reporting functionality",
        "Limited integration options"
      ],
      bestFor: "Organizations wanting flexible, customizable review processes"
    },
    {
      name: "Reflektive",
      website: "https://www.reflektive.com",
      priceRange: "$$",
      pricingModel: "Per user/month",
      userRating: 4.3,
      userReviews: 510,
      usability: 4.2,
      mobileFriendly: true,
      continuousFeedback: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Real-time feedback",
        "Goal management",
        "Performance reviews",
        "Recognition tools",
        "Integration with common business tools"
      ],
      limitations: [
        "UI can be confusing for some users",
        "Limited customization in basic tier",
        "Some reporting limitations",
        "Implementation can be challenging"
      ],
      bestFor: "Teams looking for feedback and recognition-focused performance solutions"
    },
    {
      name: "Cornerstone Performance",
      website: "https://www.cornerstoneondemand.com/performance",
      priceRange: "$$$",
      pricingModel: "Per user/month (enterprise pricing)",
      userRating: 4.0,
      userReviews: 680,
      usability: 3.8,
      mobileFriendly: true,
      continuousFeedback: true,
      companySize: "Mid-size to Enterprise",
      keyFeatures: [
        "Competency assessments",
        "Development planning",
        "Succession planning",
        "Career pathing",
        "Learning integration"
      ],
      limitations: [
        "Complex UI for casual users",
        "Requires significant configuration",
        "Higher learning curve",
        "Premium pricing"
      ],
      bestFor: "Organizations focused on development and succession planning"
    },
    {
      name: "Small Improvements",
      website: "https://www.small-improvements.com",
      priceRange: "$$",
      pricingModel: "Per active user/month",
      userRating: 4.5,
      userReviews: 490,
      usability: 4.7,
      mobileFriendly: true,
      continuousFeedback: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Simple performance reviews",
        "360° feedback",
        "Lightweight 1-on-1 meetings",
        "Praise and recognition",
        "Intuitive objectives tracking"
      ],
      limitations: [
        "Limited advanced reporting",
        "Fewer enterprise features",
        "Basic goal tracking compared to OKR-specific tools",
        "Limited advanced workflow customization"
      ],
      bestFor: "Small to medium businesses seeking an intuitive, user-friendly solution"
    },
    {
      name: "BambooHR Performance Management",
      website: "https://appwiki.nl/link/brand/1OIOrEAw4ngBccSnuIoaAmJ9r5VK5anS",
      priceRange: "$$",
      pricingModel: "Add-on to BambooHR (per employee pricing)",
      userRating: 4.6,
      userReviews: 750,
      usability: 4.5,
      mobileFriendly: true,
      continuousFeedback: false,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Automated performance reviews",
        "Self and manager assessments",
        "Goal setting and tracking",
        "Full HRIS integration",
        "Employee satisfaction surveys"
      ],
      limitations: [
        "Requires BambooHR system",
        "Limited continuous feedback features",
        "Fewer specialized performance tools",
        "Basic reporting"
      ],
      bestFor: "BambooHR customers looking for integrated performance management"
    },
    {
      name: "Engagedly",
      website: "https://engagedly.com",
      priceRange: "$$",
      pricingModel: "Per user/month",
      userRating: 4.4,
      userReviews: 480,
      usability: 4.2,
      mobileFriendly: true,
      continuousFeedback: true,
      companySize: "Small to Mid-size",
      keyFeatures: [
        "Multi-rater feedback",
        "LMS integration",
        "Social recognition",
        "Goal alignment",
        "Employee engagement surveys"
      ],
      limitations: [
        "Interface can be busy",
        "Feature-rich but can be overwhelming",
        "Mobile app has limited functionality",
        "Some customization limitations"
      ],
      bestFor: "Companies seeking an all-in-one engagement and performance platform"
    }
  ];

  // Helper function to render star ratings
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.3;
    
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary' }} />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary', opacity: 0.5 }} />);
      } else {
        stars.push(<RiStarLine key={i} sx={{ color: 'primary' }} />);
      }
    }
    
    return <div sx={{ display: 'flex', alignItems: 'center' }}>{stars} <span sx={{ ml: 1 }}>({rating}/5)</span></div>;
  };

  return (
    <Layout>
      <SEO 
        title="Top 10 Performance Management Software Compared | HR Software Guide"
        description="Compare the top 10 Performance Management Software solutions for 2025. Detailed analysis of features, pricing, usability, and user feedback to find the best solution for your organization"
        keywords={[
          "performance management software",
          "employee evaluation tools",
          "360 feedback software",
          "performance review platforms",
          "goal tracking software",
          "OKR software comparison",
          "continuous feedback tools"
        ]}
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4
          }}>
            <Link to="/software" sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary',
              textDecoration: 'none',
              fontSize: '1rem',
              fontWeight: 500
            }}>
              <RiArrowLeftSLine sx={{ mr: 1 }} /> Back to HR Software
            </Link>
          </div>
          
          <div sx={{
            textAlign: 'center',
            mb: 2,
            fontSize: '0.95rem',
            color: 'text',
            opacity: 0.8
          }}>
            Last Updated: March 2025
          </div>
          
          <h1 sx={{
            fontSize: ['1.8rem', '2.3rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            Top 10 Performance Management Software Compared
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 5,
            lineHeight: 1.5,
            color: 'text'
          }}>
            An in-depth comparison of the leading performance management solutions to help you find the right
            platform for improving employee performance, engagement, and development at your organization.
          </p>
          
          {/* Comparison criteria explanation */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: '1.3rem',
              fontWeight: 600,
              mb: 3,
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiInformationLine sx={{ mr: 2, color: 'primary' }} /> Comparison Criteria
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
              gap: 3,
              mb: 3
            }}>
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiPriceTag3Line sx={{ mr: 1, color: 'primary' }} /> Pricing
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  $ = Budget (Under $5/user/mo)<br />
                  $$ = Mid-range ($5-15/user/mo)<br />
                  $$$ = Premium ($15-25/user/mo)<br />
                  $$$$ = Enterprise (Custom pricing)
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiUserSmileLine sx={{ mr: 1, color: 'primary' }} /> User Rating
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Based on verified reviews across G2, Capterra, and TrustRadius platforms. Rating out of 5.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiComputerLine sx={{ mr: 1, color: 'primary' }} /> Usability
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Ease of use rating based on user interface intuitiveness, onboarding experience, and admin complexity.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiCustomerService2Line sx={{ mr: 1, color: 'primary' }} /> Company Size
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  The ideal organization size where the solution performs best based on features, scalability, and pricing.
                </p>
              </div>
            </div>
          </div>
          
          {/* Software comparison cards */}
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
            gap: 4,
            mb: 5
          }}>
            {pmSoftwareComparison.map((software, index) => (
              <div key={software.name} sx={{
                bg: 'white',
                p: [3, 4],
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
                }
              }}>
                <div sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3
                }}>
                  <h3 sx={{
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    color: 'primary'
                  }}>
                    {index + 1}. {software.name}
                  </h3>
                  <div sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    color: 'primary'
                  }}>
                    {software.priceRange}
                  </div>
                </div>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mb: 3
                }}>
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Pricing Model</div>
                    <div sx={{ fontSize: '0.9rem' }}>{software.pricingModel}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Company Size</div>
                    <div sx={{ fontSize: '0.9rem' }}>{software.companySize}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>User Rating ({software.userReviews} reviews)</div>
                    <div sx={{ fontSize: '0.9rem' }}>{renderStars(software.userRating)}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Usability</div>
                    <div sx={{ fontSize: '0.9rem' }}>{renderStars(software.usability)}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Mobile-Friendly</div>
                    <div sx={{ fontSize: '0.9rem' }}>
                      {software.mobileFriendly ? 
                        <span sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
                          <RiCheckboxCircleLine sx={{ mr: 1 }} /> Yes
                        </span> : 
                        <span sx={{ color: 'red', display: 'flex', alignItems: 'center' }}>
                          <RiCloseCircleLine sx={{ mr: 1 }} /> No
                        </span>
                      }
                    </div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Continuous Feedback</div>
                    <div sx={{ fontSize: '0.9rem' }}>
                      {software.continuousFeedback ? 
                        <span sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
                          <RiCheckboxCircleLine sx={{ mr: 1 }} /> Yes
                        </span> : 
                        <span sx={{ color: 'red', display: 'flex', alignItems: 'center' }}>
                          <RiCloseCircleLine sx={{ mr: 1 }} /> No
                        </span>
                      }
                    </div>
                  </div>
                </div>
                
                <div sx={{ mb: 3 }}>
                  <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Key Features</div>
                  <ul sx={{ pl: 3, mb: 0 }}>
                    {software.keyFeatures.map(feature => (
                      <li key={feature} sx={{ fontSize: '0.9rem', mb: 1 }}>{feature}</li>
                    ))}
                  </ul>
                </div>
                
                <div sx={{ mb: 3 }}>
                  <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Limitations</div>
                  <ul sx={{ pl: 3, mb: 0 }}>
                    {software.limitations.map(limitation => (
                      <li key={limitation} sx={{ fontSize: '0.9rem', mb: 1 }}>{limitation}</li>
                    ))}
                  </ul>
                </div>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 2,
                  borderRadius: '4px',
                  textAlign: 'center',
                  fontWeight: 600,
                  mb: 3
                }}>
                  Best For: {software.bestFor}
                </div>
                
                <div sx={{ mt: 'auto' }}>
                  <a href={software.website} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    sx={{
                      display: 'block',
                      textAlign: 'center',
                      bg: 'primary',
                      color: 'white',
                      py: 2,
                      px: 3,
                      borderRadius: '4px',
                      textDecoration: 'none',
                      fontWeight: 600,
                      fontSize: '0.95rem',
                      transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                      '&:hover': {
                        bg: 'primaryDark',
                        color: 'white',
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                      }
                    }}
                  >
                    Visit Website
                  </a>
                </div>
              </div>
            ))}
          </div>
          
          {/* Selection guide */}
          <div sx={{
            bg: 'white',
            p: [3, 4],
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            mb: 5
          }}>
            <h2 sx={{ 
              fontSize: '1.4rem',
              fontWeight: 600,
              mb: 3,
              pb: 2,
              borderBottom: '1px solid',
              borderColor: 'primaryMuted'
            }}>
              How to Select the Right Performance Management Software
            </h2>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Assess Your Performance Philosophy</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Before evaluating software, clarify your organization's approach to performance management. Are you focused on 
                traditional annual reviews, continuous feedback, OKR (Objectives and Key Results) methodology, or a hybrid approach? 
                Different platforms excel in different methodologies, so understanding your philosophy will help narrow your options. 
                Consider how your performance processes align with your company culture and management style.
              </p>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Prioritize User Experience</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                The best performance management software in the world won't add value if employees and managers don't use it. 
                Evaluate solutions based on their intuitiveness and user-friendliness. Look for platforms that minimize administrative 
                burden while maximizing engagement. Consider the experience from different perspectives: employees giving and receiving 
                feedback, managers conducting reviews, and HR teams administering the system.
              </p>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Consider Integration Requirements</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Performance management doesn't happen in isolation. Your chosen platform should integrate with your core HR systems, 
                communication tools, and other workflow applications. Consider how performance data will flow between systems, particularly 
                for compensation reviews, succession planning, and learning and development initiatives. Native integrations are preferable 
                to custom connections that require ongoing maintenance.
              </p>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Evaluate Customization Capabilities</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Every organization has unique performance needs. The right software should be flexible enough to accommodate your 
                specific processes, terminology, and evaluation criteria. However, be cautious of solutions that require extensive 
                customization to be functional. Look for platforms that offer configurable templates and workflows that can be adjusted 
                without developer intervention or significant cost.
              </p>
            </div>
            
            <div>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Analyze Reporting and Analytics</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Performance data is only valuable if you can extract meaningful insights. Evaluate each platform's reporting capabilities 
                and how they align with your analytics needs. Consider what performance metrics are most important to your organization and 
                ensure the software can track and visualize them effectively. Look for tools that help identify trends, highlight potential 
                bias, and connect performance data to business outcomes.
              </p>
            </div>
          </div>
          
          {/* Trends in performance management technology */}
          <div sx={{
            bg: 'white',
            p: [3, 4],
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
          }}>
            <h2 sx={{ 
              fontSize: '1.4rem',
              fontWeight: 600,
              mb: 3,
              pb: 2,
              borderBottom: '1px solid',
              borderColor: 'primaryMuted'
            }}>
              Current Trends in Performance Management Technology
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Continuous Feedback Models</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Traditional annual reviews are increasingly being replaced or supplemented with continuous feedback mechanisms. 
                  Modern performance management platforms facilitate real-time feedback, regular check-ins, and ongoing goal adjustments. 
                  This approach helps organizations respond faster to changing conditions, provide timely coaching, and maintain 
                  alignment throughout the year rather than retrospectively assessing performance.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>OKR Integration</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Objectives and Key Results (OKRs) methodology continues to gain traction as a goal-setting framework, and performance 
                  management software has evolved to support this approach. Leading platforms now offer specialized OKR tracking, visualization 
                  of goal alignment, and progress metrics. These tools help create transparency around objectives, connect individual 
                  contributions to organizational goals, and foster agility in goal adjustment.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>AI-Powered Insights</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Artificial intelligence is transforming performance management by unlocking deeper insights from performance data. 
                  Advanced platforms now offer natural language processing to analyze feedback sentiment, identify bias in reviews, 
                  suggest development opportunities, and even predict future performance trends. These capabilities help organizations 
                  move beyond simple performance documentation to strategic talent development and workforce planning.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Employee-Centric Design</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Performance management tools are increasingly designed with the employee experience as the primary focus. 
                  This shift recognizes that engagement with the process is essential for effectiveness. Modern platforms feature 
                  intuitive interfaces, mobile accessibility, employee self-service capabilities, and nudges to encourage participation. 
                  Some platforms incorporate elements of gamification to increase engagement with feedback and goal management.
                </p>
              </div>
            </div>

            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4
            }}>
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Integration of Wellbeing</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  There's growing recognition that performance and wellbeing are interconnected. Innovative performance management 
                  platforms now incorporate features to monitor workload, prevent burnout, and track wellness factors that impact 
                  productivity. Some systems include check-in questions about energy levels and work-life balance, provide managers 
                  with insights about potential burnout risks, and help organizations balance high performance with sustainable workloads.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Skill-Based Development</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Performance management is increasingly linked with skill development and career growth. Modern platforms now map 
                  performance to skill acquisition, connect feedback to learning opportunities, and help employees plot development 
                  paths. These capabilities transform performance management from an evaluation process to a development engine, 
                  helping organizations build critical capabilities while addressing employees' desire for growth and advancement.
                </p>
              </div>
            </div>
          </div>
          
          {/* Get Expert Help CTA */}
          <div sx={{
            bg: 'primary',
            borderRadius: '12px',
            p: 4,
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 6
          }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'], 
              fontWeight: 700, 
              color: 'white',
              mb: 3 
            }}>
              Need Help Selecting the Right Performance Management Software?
            </h2>
            <p sx={{ 
              fontSize: ['1rem', '1.1rem'], 
              color: 'white',
              mb: 4,
              opacity: 0.9
            }}>
              Our HR technology experts can provide personalized recommendations based on your organization's 
              performance philosophy, feedback culture, and process requirements.
            </p>
            <div sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              justifyContent: 'center',
              gap: 3
            }}>
              <Link to="/contact" sx={{
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  color: 'primary'
                }
              }}>
                Schedule a Free Consultation
              </Link>
              <Link to="/services/hr-software-selection" sx={{
                bg: 'transparent',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                border: '2px solid white',
                transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'primaryDark',
                  color: 'white'
                }
              }}>
                Learn About Our Selection Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PerformanceManagementPage; 